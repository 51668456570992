import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AnalyticsBrowser,
    AnalyticsSettings,
    Context,
    EventProperties,
    Options,
    User
} from '@segment/analytics-next';
import { BehaviorSubject, debounceTime, filter, share, switchMap, take } from 'rxjs';

import { environment } from '../../../environments/environment';
import versions from '../../../versions';

@Injectable()
export class AppTrackerService {
    private _analytics = new AnalyticsBrowser();
    private _user$ = new BehaviorSubject<User>(undefined);
    private _initialized$ = new BehaviorSubject<boolean>(false);

    readonly ready$ = this._initialized$.pipe(
        filter((status) => !!status),
        take(1),
        share()
    );

    readonly user$ = this.ready$.pipe(
        debounceTime(0),
        switchMap(() => this._user$),
        share()
    );

    get disabled() {
        return isPlatformServer(this.platformId);
    }
    get enabled() {
        return !this.disabled;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: object,
        private _router: Router,
        private _route: ActivatedRoute
    ) {
        if (this.disabled) {
            return undefined;
        }

        // Make sure GA Data stream is included from get go when Tag Manager is
        // started.
        if (!this.disabled) {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                measurementId: environment.firebase.measurementId,
                appVersion: versions.version
            });
        }

        // Once Segment is loaded and we have initialized the service, start
        // listening for updates to the user through "Identify"-events.
        this.ready$.subscribe({
            next: () =>
                this._analytics.on('identify', () =>
                    setTimeout(() => this._analytics.user().then((user) => this._user$.next(user)))
                )
        });

        this._analytics
            .ready()
            .then(() => this._analytics.user())
            .then((user) => {
                this._user$.next(user);

                const userId = user.id();
                const url = this.document.location.toString();
                const urlInstance = new URL(url);
                const queryUserId = urlInstance.searchParams.get('usr');

                try {
                    if (queryUserId && queryUserId !== userId) {
                        this._analytics.identify(queryUserId);
                    }
                } catch (error) {
                    console.log(error);
                }

                if (queryUserId) {
                    this._router.navigate([], {
                        relativeTo: this._route,
                        replaceUrl: true,
                        queryParamsHandling: 'merge',
                        queryParams: {
                            usr: null
                        }
                    });
                }
            })
            .then(() => this._initialized$.next(true));
    }

    load({ writeKey }: AnalyticsSettings): AnalyticsBrowser {
        if (this.disabled) {
            return undefined;
        }

        return this._analytics.load({ writeKey });
    }

    user(): Promise<User> {
        return this._analytics.user();
    }

    reset(): void {
        if (this.disabled) {
            return;
        }

        window['dataLayer'].push({
            index: null,
            products: null,
            ecommerce: null,
            queryID: null
        });
    }

    ////////////////////////////////////////////////////////////////////////////
    // Mirror tracking methods in SegmentAnalytics
    ////////////////////////////////////////////////////////////////////////////

    track(event: string, properties: EventProperties = {}, options?: Options): Promise<Context> {
        if (this.disabled) {
            return Promise.resolve(undefined);
        }

        return this._analytics.track(event, properties, options);
    }

    customEvent(event: string, options?: Options): Promise<Context> {
        if (this.disabled) {
            return Promise.resolve(undefined);
        }

        return this._analytics.track(
            'custom_event',
            {
                customEventName: event
            },
            options
        );
    }

    identify(userId?: string, traits?: object, options?: Options): Promise<Context> {
        if (this.disabled) {
            return Promise.resolve(undefined);
        }

        return this._analytics.identify(userId, traits, options);
    }

    page(name?: string, properties: EventProperties = {}, options?: Options): Promise<Context> {
        if (this.disabled) {
            return Promise.resolve(undefined);
        }

        return this._analytics.page(null, null, properties, options);
    }
}
